<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <div class="control_box">
        <Search-bar :inputs="inputs" :selects="selects" :timeRange="timeRange" @search="search" @reset="search" />
        <div class="control_box_bottom">
          <a-button type="primary" icon="printer" @click="downloadExcel" :disabled="tableData.length == 0"
            style="margin-right:10px;">导出</a-button>
          <a-button type="primary" icon="arrow-right" @click="changeTable" style="margin-right:10px;">
            {{isOrderTable ? '查看销量统计' : '查看订单列表'}}</a-button>
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="show_box">
        <a-table v-show="isOrderTable" :columns="columns" :data-source="tableData" :loading="loading"
          :rowKey="tableData => tableData.id" :pagination="pagination" @change="handleTableChange">
          <span slot="state" slot-scope="record">
            {{record.is_pay == 0 ? '未支付' : (record.is_pay == 3 ? '已退款' : (record.is_pay == 1 && record.status == 0 ? '未支付' : (record.is_pay == 1 && record.status == 1 ? '待配送' : (record.is_pay == 1 && record.status == 2 ? '已配送' : (record.is_pay == 1 && record.status == 3 ? '已取消' : '')))))}}
          </span>
          <span slot="dis" slot-scope="record">
            {{record.dis == 1 ?'送货上门' : (record.dis == 2 ? '自取' : '')}}
          </span>
          <span slot="action" slot-scope="record">
            <a-button :disabled="!record.get_user_address" type="link" @click="getSendAdd(record)">送货地址</a-button>
            <a-divider type="vertical" />
            <a-button :disabled="record.is_pay != 1 || record.status != 1" type="link" @click="sendFn(record)">配送
            </a-button>
            <a-divider type="vertical" />
            <a-button :disabled="record.is_pay != 1 || record.status != 1" type="link" @click="deleteFn(record)">取消
            </a-button>

          </span>
        </a-table>
        <a-table v-show="!isOrderTable" :columns="columnsSale" :data-source="saleData" :loading="loading"
          :rowKey="saleData => saleData.goods_id" :pagination="false">
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
//引入接口api
import {
  getGoodsOrder,
  cancelOrder,
  updateOrder,
  getGoodsSales,
  officesWithout,
  postsWithout,
  userLog
} from "@/request/api";
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    SearchBar,
    MyBreadcrumb,
  },
  data () {
    return {
      //传给MyBreadcrumb的参数
      breadcrumbs: [
        {
          key: 0,
          title: "用餐管理",
        },
        {
          key: 1,
          title: "小卖部订单管理",
        },
      ],

      //传给searchBar公共搜索组件的数据
      timeRange: {
        show: true,
        placeholderStart: "下单时间",
        placeholderEnd: "下单时间",
      },
      inputs: [
        {
          placeholder: "姓名",
          model: "realname",
          width: 100,
        },
        {
          placeholder: "手机",
          model: "mobile",
          width: 120,
        },
      ],
      selects: [
        {
          model: "office_id",
          placeholder: "部门",
          width: 200,
          options: [
            {
              key: "",
              value: "部门",
            },
          ],
        },
        {
          model: "post_id",
          placeholder: "职务",
          width: 100,
          options: [
            {
              key: "",
              value: "职务",
            },
          ],
        },
        {
          model: "status",
          placeholder: "订单状态",
          width: 100,
          options: [
            {
              key: "",
              value: "订单状态",
            },
            {
              key: 0,
              value: "未支付",
            },
            {
              key: 1,
              value: "待配送",
            },
            {
              key: 2,
              value: "已配送",
            },
            {
              key: 3,
              value: "已取消",
            },
          ],
        },
      ],


      //请求参数
      queryParam: {
        page: 1,
        begin_date: "",
        end_date: "",
        post_id: "",
        status: "",
        mobile: "",
        realname: "",
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      dataForExport: [], //不分页的数据
      loading: false, //table加载状态

      // 表格的列配置
      columns: [
        {
          title: "订单号",
          key: "order_number",
          dataIndex: "order_number",
        },
        {
          title: "姓名",
          key: "realname",
          dataIndex: "realname",
        },
        {
          title: "部门",
          key: "office",
          dataIndex: "office",
        },
        {
          title: "金额",
          key: "money",
          dataIndex: "money",
        },
        {
          title: "手机",
          key: "mobile",
          dataIndex: "mobile",
        },
        {
          title: "订单状态",
          key: "status",
          scopedSlots: { customRender: "state" },
        },
        {
          title: "下单时间",
          key: "created_at",
          dataIndex: "created_at",
        },
        {
          title: "配送方式",
          key: "dis",
          scopedSlots: { customRender: "dis" },
        },
        {
          title: "订单详情",
          key: "get_order_info",
          dataIndex: "get_order_info",
        },
        {
          title: "核销时间",
          key: "updated_at",
          dataIndex: "updated_at",
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      //页配置
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
      },


      // 销量统计报表参数
      isOrderTable: true,
      columnsSale: [
        {
          title: "商品名称",
          key: "goods_title",
          dataIndex: "goods_title",
        },
        {
          title: "商品类型",
          key: "goods_type",
          dataIndex: "goods_type",
        },
        {
          title: "商品总收益",
          key: "sum_total",
          dataIndex: "sum_total",
        },
        {
          title: "商品总销量",
          key: "sum_num",
          dataIndex: "sum_num",
        }
      ],
      saleData: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //刷新和搜索一样的方法
    search (val) {
      this.queryParam = {
        page: 1,
        begin_date: val.timeRange.startTime,
        end_date: val.timeRange.endTime,
        mobile: val.input.mobile ? val.input.mobile : "",
        realname: val.input.realname ? val.input.realname : "",
        created_end: val.input.created_end ? val.input.created_end : "",
        status: val.select.status === '' ? "" : val.select.status,
        office_id: val.select.office_id ? val.select.office_id : "",
        post_id: val.select.post_id ? val.select.post_id : "",
      };
      this.pagination.current = 1;
      this.getTableData();
      this.getSaleData();
    },

    //获取表格数据
    getTableData () {
      this.loading = true;
      this.queryParam.no_paging = 0;
      getGoodsOrder(this.queryParam).then((res) => {
        let resultData = res.data;
        //处理页码
        const pagination = { ...this.pagination };
        pagination.total = resultData.total;
        this.pagination = pagination;
        if (resultData.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        this.tableData = resultData.data.map(item => {
          item = {
            ...item,
            realname: item.get_user.realname,
            mobile: item.get_user.mobile,
            office: item.get_user.office.title,
            // post:item.get_user.post.title,
          }
          return item
        });
        this.loading = false;
      });

      //请求不分页的数据
      this.queryParam.no_paging = 1;
      getGoodsOrder(this.queryParam).then((res) => {
        this.dataForExport = res.data.map(item => {
          item = {
            ...item,
            realname: item.get_user.realname,
            office: item.get_user.office.title,
            post: item.get_user.post.title,
            mobile: item.get_user.mobile,
            status: item.is_pay == 0 ? '未支付' : (item.is_pay == 3 ? '已退款' : (item.is_pay == 1 && item.status == 0 ? '未支付' : (item.is_pay == 1 && item.status == 1 ? '待配送' : (item.is_pay == 1 && item.status == 2 ? '已配送' : (item.is_pay == 1 && item.status == 3 ? '已取消' : ''))))),
            dis: item.dis == 1 ? '送货上门' : (item.dis == 2 ? '自取' : ''),
            address: item.get_user_address ? item.get_user_address.addr : ""
          }
          return item
        });
      });
    },

    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    //导出
    downloadExcel () {
      this.$confirm({
        title: "确定导出表格?",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          if(this.isOrderTable){
              //订单数据导出
              this.export2Excel();
          } else {
              this.saleExport2Excel();
          }
          
        },
        onCancel: () => { },
      });
    },

    //订单数据写入excel
    export2Excel () {
      var that = this;
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/excel/export2Excel"); //这里必须使用绝对路径，使用@/+存放export2Excel的路径
        const tHeader = ["订单号", "姓名", "部门", "职务", "金额", "手机", "订单状态", "下单时间", "配送方式", "订单明细", "核销时间","送货地址"]; // 导出的表头名信息
        const filterVal = ["order_number", "realname", "office", "post", "money", "mobile", "status", "created_at", "dis", "get_order_info", "updated_at", "address"]; // 导出的表头字段名，需要导出表格字段名
        const list = that.dataForExport;
        const data = that.formatJson(filterVal, list);

        export_json_to_excel(tHeader, data, "小卖部订单数据"); // 导出的表格名称，根据需要自己命名
        this.userLogFn()
      });
    },
    //销量数据写入excel
    saleExport2Excel () {
      var that = this;
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/excel/export2Excel"); //这里必须使用绝对路径，使用@/+存放export2Excel的路径
        const tHeader = ["商品名称", "商品类型", "商品总收益", "商品总销量"]; // 导出的表头名信息
        const filterVal = ["goods_title", "goods_type", "sum_total", "sum_num"]; // 导出的表头字段名，需要导出表格字段名
        const list = that.saleData;
        const data = that.formatJson(filterVal, list);

        export_json_to_excel(tHeader, data, "小卖部销售数据"); // 导出的表格名称，根据需要自己命名
        this.userLogFn()
      });
    },

    //格式转换
    formatJson (filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    //获取部门列表
    getOffices () {
      officesWithout({ no_paging: 1 }).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          this.selects[0].options.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
        }
      });
    },

    //获取职务列表
    getPosts () {
      postsWithout({ no_paging: 1 }).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          this.selects[1].options.push({
            key: res.data[i].id,
            value: res.data[i].title,
          });
        }
      });
    },

    getSaleData () {
      delete this.queryParam.status
      getGoodsSales(this.queryParam).then(res => {
        this.saleData = res.data
      })
    },

    changeTable () {

      if (this.isOrderTable) {
        this.isOrderTable = false

        this.selects.pop()
      } else {
        this.isOrderTable = true
        this.selects.push({
          model: "status",
          placeholder: "订单状态",
          width: 100,
          options: [
            {
              key: "",
              value: "订单状态",
            },
            {
              key: 0,
              value: "未支付",
            },
            {
              key: 1,
              value: "待配送",
            },
            {
              key: 2,
              value: "已配送",
            },
            {
              key: 3,
              value: "已取消",
            },
          ],
        })
      }
    },

    getSendAdd (record) {
      let addr = record.get_user_address.addr, mobile = record.get_user_address.mobile, name = record.get_user_address.name;
      this.$info({
        content: `姓名：${name}，联系方式：${mobile}，地址：${addr}`
      })
    },
    deleteFn (record) {
      let id = record.id, orderNumber = record.order_number;
      this.$confirm({
        content: `是否取消订单${orderNumber}?`,
        onOk: () => {
          cancelOrder(id).then(res => {
          }).catch(err => {
            if (err.status == 201) {
              this.$message.success('取消成功')
              // this.queryParam.page = 1;
              // this.pagination.current = 1;
              this.getTableData();
            } else {
              this.$message.error(err.data.msg)
            }
          })
        },
      })
    },
    sendFn (record) {
      let id = record.id, orderNumber = record.order_number;
      this.$confirm({
        content: `是否配送订单${orderNumber}?`,
        onOk: () => {
          updateOrder(id).then(res => {

          }).catch(err => {
            if (err.status == 201) {
              this.$message.success('配送成功')
              // this.queryParam.page = 1;
              // this.pagination.current = 1;
              this.getTableData();
            } else {
              this.$message.error(err.data.msg)
            }
          })
        },
      })
    },
    userLogFn(){
      let moduleId = this.$route.meta.moduleId,
          param = {
            begin_date:this.queryParam.begin_date,
            end_date:this.queryParam.end_date,
            realname:this.queryParam.realname,
            mobile:this.queryParam.mobile,
            office:this.queryParam.office_id ? this.selects[0].options.find(item => item.key === this.queryParam.office_id).value : '',
            post:this.queryParam.post_id ? this.selects[1].options.find(item => item.key == this.queryParam.post_id).value : '',
            status:this.queryParam.status ? this.selects[2].options.find(item => item.key === this.queryParam.status).value : '',
            total:this.dataForExport.length
          };
      userLog(moduleId,param).then(res => {}).catch(err => {
        console.log(err)
      })
    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getTableData();
    this.getSaleData();
    this.getOffices();
    this.getPosts();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  background-color: #f0f2f5;
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
</style>